import axios from "axios";
import { getToken } from "./auth";

const newBaseApi = axios.create({
  baseURL: process.env.REACT_APP_NEW_API_URL,
});

newBaseApi.interceptors.request.use(async (config) => {
  const token = getToken(); // Token direto do localStorage

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export default newBaseApi;
