import {
  FormControl,
  FormControlLabel,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  withStyles,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FooterImage from "../../Assets/amico.png";
import api from "../../Services/api";
import newBaseApi from "../../Services/newBaseApi";
import Footer from "../../components/Footer/Footer";
import { Check } from "./components/Check";
import { Open } from "./components/Open";
import { SupplierCard } from "./components/SupplierCard";
import "./style.css";
import { PhoneMask } from "./utils/masks";
import { AuthContext } from "../../context/AuthContext";
import { SignIn } from "./components/SignIn";

const Input = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: "6px",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: "13px",
    width: "100%",
    padding: "10px 12px",
  },
}))(InputBase);

const CustomTextField = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    outline: "none",
  },
  input: {
    borderRadius: "6px",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: "13px",
    width: "100%",
    // padding: "10px 12px",
  },
}))(TextField);

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    marginRight: "5px",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#0F62FE",
        borderColor: "#0F62FE",
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const initialValue = {
  nome_evento: "",
  tipo_evento: "",
  tema_evento: "",
  local_evento: {
    uf: "",
    city: "",
  },
  servicos_selecionados: [],
  descricao_evento: "",
  evento_publico: false,
  data_inicio: "",
  hora_inicio: "",
  data_termino: "",
  hora_termino: "",
  convidados_evento: "",
  orcamento_evento: "",
  url_presentes_evento: "",
  nome_responsavel: "",
  email_responsavel: "",
  telefone_responsavel: "",
};

export default function CriarMeuEvento() {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [suppliers, setSuppliers] = useState([]);
  const [isSuppliersLoading, setIsSuppliersLoading] = useState(false);
  const [eventData, setEventData] = useState(initialValue);
  const [datetime, setDatetime] = useState("");
  const [endDatetime, setEndDatetime] = useState("");
  const [eventTypes, setEventTypes] = useState([]);
  const [eventThemes, setEventThemes] = useState([]);
  const [eventServices, setEventServices] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  useEffect(() => {
    getEventTypes();
    getEventThemes();
    getEventServices();
    getStates();
  }, []);


  async function getEventTypes() {
    const { data } = await api.get("/tiposeventos");
    setEventTypes(data);
  }

  async function getEventThemes() {
    const { data } = await api.get("/temaestabelecimento");
    setEventThemes(data);
  }

  async function getEventServices() {
    const { data } = await api.get("/ramo");
    setEventServices(data);
  }

  async function getStates() {
    const { data } = await api.get("/estados");
    setStates(data);
  }

  async function getCities(stateId) {
    const { data } = await api.get(`/cidades/${stateId}`);
    setCities(data);
  }

  function formatarMoedaPos(e) {
    const { value } = e;
    let valor = value;
    valor = valor + "";
    valor = parseInt(valor.replace(/[\D]+/g, ""));
    valor = valor + "";
    valor = valor.replace(/([0-9]{2})$/g, ",$1");

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }
    setEventData((state) => ({ ...state, orcamento_evento: valor }));
    if (valor == "NaN") {
      setEventData((state) => ({ ...state, orcamento_evento: "" }));
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;

    setEventData((state) => ({ ...state, [name]: value }));
  }

  function handleChangeState(event) {
    setEventData((state) => ({
      ...state,
      local_evento: { uf: event.target.value },
    }));
    getCities(event.target.value);
  }

  async function handleSuppliers(suppliers) {
    setIsSuppliersLoading(true);

    const { data } = await newBaseApi.post("/anunciante/buscar/anunciante", {
      city_id: eventData.local_evento.city,
      services_ids: suppliers,
    });

    setSuppliers(data);
    setIsSuppliersLoading(false);
  }

  function splitDate(date) {
    const splitedDate = date.split("T");
    return [splitedDate[0], splitedDate[1]];
  }

  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  async function submitEventForm(e) {
    e.preventDefault();
    setError(undefined);
    setIsLoading(true);

    const cleanedValue = parseFloat(
      eventData.orcamento_evento.replace(".", "").replace(",", ".")
    );

    const payload = {
      ...eventData,
      orcamento_evento: cleanedValue,
      convidados_evento: Number(eventData.convidados_evento),
      data_inicio: splitDate(datetime)[0],
      hora_inicio: splitDate(datetime)[1],
      data_termino: splitDate(endDatetime)[0],
      hora_termino: splitDate(endDatetime)[1],
    };

    const { url_presentes_evento, ...rest } = payload;

    const hasSomeEmptyField = Object.values(rest).some((item) => item === "");

    if (hasSomeEmptyField) {
      setError("Preencha todos os campos!");
      setIsLoading(false);
      // setOpen(true);
      return;
    }

    try {
      const response = await newBaseApi.put("/anunciante/evento", payload);
      if (response.data.error === "true") {
        setError(response.data.message)
        setIsLoading(false);
        return;
      }
      // setOpenSuccess(true);
      history.push(`/dashboard/eventos-consumidor/${user.id}`);
    } catch (err) {
      setError(error.response?.data.message || error.message);
      console.error("Erro ao criar evento:", error.response?.data || error.message);
    } finally {
      setIsLoading(false);
    }
  }

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpen(false);
  // };

  // const handleCloseSuccess = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpenSuccess(false);
  //   history.push("/");
  // };

  function handleCardCheck(supplierdId) {
    let newArr = [];
    const supplierAlreadyExists = eventData.servicos_selecionados.some(
      (item) => item === supplierdId
    );

    if (supplierAlreadyExists) {
      newArr = eventData.servicos_selecionados.filter(
        (item) => item !== supplierdId
      );
    }

    setEventData((state) => ({
      ...state,
      servicos_selecionados: supplierAlreadyExists
        ? [...newArr]
        : [...state.servicos_selecionados, supplierdId],
    }));
  }

  function handleAboutIcon(id, anunciante_id) {
    window.open(`/anuncio/${id}/${anunciante_id}`, "_blank");
  }

  useEffect(() => {
    if (user) {
      setEventData(p => ({
        ...p,
        nome_responsavel: user.name,
        email_responsavel: user.email,
        telefone_responsavel: user.cellphone,
      }))
    }
  }, [user]);

  return (
    <>
      <div className="event">
        <h2 className="event__title">Criar meu evento</h2>

        <form className="event__form" onSubmit={submitEventForm}>
          <FormControl fullWidth>
            <InputLabel shrink>Nome do evento*</InputLabel>
            <Input
              name="nome_evento"
              value={eventData.nome_evento}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel shrink>Tipo do evento*</InputLabel>
                <Select
                  name="tipo_evento"
                  value={eventData.tipo_evento}
                  onChange={(e) => handleChange(e)}
                  input={<Input />}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, 
                        overflowY: "auto", 
                        marginTop: 10, 
                      },
                    },
                  }}
                >
                  {eventTypes.map((type) => (
                    <MenuItem value={type.id} key={type.id}>
                      {type.descricao}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel shrink>Tema / Estilo*</InputLabel>
                <Select
                  name="tema_evento"
                  value={eventData.tema_evento}
                  onChange={(e) => handleChange(e)}
                  input={<Input />}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, 
                        overflowY: "auto", 
                        marginTop: 10, 
                      },
                    },
                  }}
                >
                  {eventThemes.map((theme) => (
                    <MenuItem value={theme.id} key={theme.id}>
                      {theme.descricao}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel shrink>Estado*</InputLabel>
                <Select
                  name="state"
                  value={eventData.local_evento.uf}
                  onChange={(e) => handleChangeState(e)}
                  input={<Input />}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, 
                        overflowY: "auto", 
                        marginTop: 10, 
                      },
                    },
                  }}
                >
                  {states.map((state) => (
                    <MenuItem value={state.id} key={state.id}>
                      {state.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel shrink>Cidade*</InputLabel>
                <Select
                  name="city"
                  value={eventData.local_evento.city}
                  onChange={(e) =>
                    setEventData((state) => ({
                      ...state,
                      local_evento: {
                        ...state.local_evento,
                        city: e.target.value,
                      },
                    }))
                  }
                  input={<Input />}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, 
                        overflowY: "auto", 
                        marginTop: 10, 
                      },
                    },
                  }}
                >
                  <MenuItem value="">Selecione um estado</MenuItem>
                  {cities.map((city) => (
                    <MenuItem value={city.id} key={city.id}>
                      {city.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <FormControl fullWidth>
            <Autocomplete
              multiple
              id="tags-standard"
              options={eventServices}
              getOptionLabel={(option) => option.descricao}
              defaultValue={[]}
              size="small"
              onChange={(_, value) => handleSuppliers(value.map((i) => i.id))}
              disabled={
                eventData.local_evento.city === "" &&
                eventData.local_evento.uf === ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Selecione quais serviços você precisa cotar para esse evento*"
                  helperText="Selecione uma cidade antes de procurar por fornecedores."
                />
              )}
            />
          </FormControl>

          <div className="event__suppliers">
            <p>
              Conheça e escolha para quais fornecedores você deseja solicitar um
              orçamento:
            </p>
            <span>
              Clique em <Open width={16} height={16} color={"#c6c6c6"} /> para
              ver mais detalhes do anunciante e em{" "}
              <Check width={16} height={16} color={"#c6c6c6"} /> para
              selecionar.
            </span>

            {suppliers.length ? (
              <div className="supplier__cards">
                {suppliers.map((supplier) => (
                  <div className="supplier__card">
                    <SupplierCard
                      supplier={supplier}
                      onClick={(id) => handleCardCheck(id)}
                      handleAbout={(id, anunciante_id) =>
                        handleAboutIcon(id, anunciante_id)
                      }
                      checked={eventData.servicos_selecionados.find(
                        (item) => item === supplier.anuncio_id
                      )}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="supplier__empty-cards">
                <p>
                  {isSuppliersLoading
                    ? "Carregando..."
                    : "Nenhum fornecedor encontrado."}
                </p>
              </div>
            )}
          </div>

          <FormControl fullWidth>
            <InputLabel shrink>Descrição do evento*</InputLabel>
            <Input
              rows="6"
              multiline
              name="descricao_evento"
              value={eventData.descricao_evento}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>

          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <FormControlLabel
                style={{ marginLeft: 0 }}
                control={
                  <AntSwitch
                    name="evento_publico"
                    value={eventData.evento_publico}
                    onChange={(e) =>
                      setEventData((state) => ({
                        ...state,
                        evento_publico: e.target.checked,
                      }))
                    }
                  />
                }
                label="Evento fechado"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel shrink>n.º de convidados*</InputLabel>
                <Input
                  name="convidados_evento"
                  value={eventData.convidados_evento}
                  onChange={(e) => handleChange(e)}
                  type="number"
                />
              </FormControl>

              <FormControl fullWidth style={{ marginTop: "18px" }}>
                <InputLabel shrink>Data e hora do evento*</InputLabel>
                <Input
                  name="datetime"
                  value={datetime}
                  onChange={(e) => setDatetime(e.target.value)}
                  type="datetime-local"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={5} spacing={2}>
              <FormControl fullWidth>
                <InputLabel shrink>Valor do orçamento*</InputLabel>
                <Input
                  name="orcamento_evento"
                  value={
                    eventData.orcamento_evento === "NaN"
                      ? 0
                      : `R$ ${eventData.orcamento_evento}`
                  }
                  onChange={(e) => formatarMoedaPos(e.target)}
                />
              </FormControl>

              <FormControl
                fullWidth
                style={{
                  display: !eventData.singleDay ? "" : "none",
                  marginTop: "18px",
                }}
              >
                <InputLabel shrink>
                  Data e hora de término do evento*
                </InputLabel>
                <Input
                  name="endDatetime"
                  value={endDatetime}
                  onChange={(e) => setEndDatetime(e.target.value)}
                  type="datetime-local"
                />
              </FormControl>
            </Grid>
          </Grid>

          <FormControl fullWidth>
            <InputLabel shrink>URL para lista de presentes</InputLabel>
            <Input
              name="url_presentes_evento"
              value={eventData.url_presentes_evento}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>

          {user && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <FormControl fullWidth>
                  <InputLabel shrink>
                    Nome do responsável pelo evento *
                  </InputLabel>
                  <Input
                    disabled
                    name="nome_responsavel"
                    value={eventData.nome_responsavel}
                    onChange={(e) => handleChange(e)}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel shrink>E-mail de contato *</InputLabel>
                  <Input
                    disabled
                    name="email_responsavel"
                    value={eventData.email_responsavel}
                    onChange={(e) => handleChange(e)}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel shrink>Telefone de contato *</InputLabel>
                  <Input
                    disabled
                    name="telefone_responsavel"
                    value={PhoneMask(eventData.telefone_responsavel)}
                    onChange={(e) => handleChange(e)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}

          {error && (
            <p className="error-message" style={{
              textAlign: "center"
            }}>{error}</p>
          )}
        
          <div className="event__form-footer">
            {user && (
              <button type="submit" disabled={isLoading}>
                {isLoading ? "Criando evento" : "Criar meu evento agora"}
              </button>
            )}
            <img src={FooterImage} alt="footer img" />
          </div>
            
        </form>
        {!user && (
          <SignIn/>
        )}
      </div>

      <Footer noShadow />

      {/* <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Preencha todos os campos!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openSuccess}
        autoHideDuration={2000}
        onClose={handleCloseSuccess}
      >
        <Alert onClose={handleCloseSuccess} severity="success">
          Evento criado com sucesso!
        </Alert>
      </Snackbar> */}
    </>
  );
}
