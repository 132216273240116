import React from "react";
import { FiMail } from "react-icons/fi";
import { IoMdArrowDropdown } from "react-icons/io";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "../../Assets/LUPPNG.png";
import facebook from "../../Assets/facebook.png";
import instagram from "../../Assets/instagram.png";
import "../header/header.css";

export default function Header() {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const isMyEventScreen = pathname === "/criar-meu-evento";

  function openMenu(e) {
    const menu = document.querySelector(".menu-container-responsive");
    const body = document.querySelector("body");
    e.preventDefault();
    menu.classList.add("menu_open");
    body.classList.add("menu-body");
  }
  function closeMenu(e) {
    const menu = document.querySelector(".menu-container-responsive");
    const body = document.querySelector("body");
    e.preventDefault();
    menu.classList.remove("menu_open");
    body.classList.remove("menu-body");
  }

  function dropdownOpen(e) {
    const dropdown = document.querySelector(".dropdown-mobile");
    e.preventDefault();
    dropdown.classList.toggle("dropdown_open");
  }
  function dropdownOpenLast(e) {
    const dropdown = document.querySelector(".dropdown-mobile-last");
    e.preventDefault();
    dropdown.classList.toggle("dropdown_open");
  }

  return (
    <header
      id="fix"
      className="header"
      style={{
        boxShadow: isMyEventScreen ? "none" : "0px 2px 22px 0px #a7a7a7",
      }}
    >
      <div>
        {/* Header Desktop */}
        <div className="container header-desktop">
          <div>
            <Link to="/">
              {" "}
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="menu-container">
            <div className="auth">
              <Link className="auth-link" to="/login-consumidor">Entrar</Link>
            </div>
            <div className="contato">
              <div>
                <FiMail color="#a4416f" size="22" />
                <span>contato@lup.com.br</span>
              </div>

              <div className="redes-sociais">
                <a href="https://www.facebook.com/plataformalup/">
                  <img src={facebook} alt="redes sociais" />
                </a>
                <a href="https://www.instagram.com/lup.com.br/">
                  <img src={instagram} alt="redes sociais" />
                </a>
              </div>
            </div>
            <div className="menu">
              <nav>
                <Link to="/">Home</Link>
                {/* <a href="https://drops.lup.com.br/">Blog</a> */}
                {/* <div className="dropdown">
                  <h2>
                    Consultar
                    <span>
                      <IoMdArrowDropdown />
                    </span>
                  </h2>
                  <div className="dropdowncontent">
                    <Link className="linkDropdown" to="/convite">
                      Convite
                    </Link>
                    <div>
                      <Link to="/filter/none">Anunciantes</Link>
                    </div>

                    <Link
                      className="linkDropdown dropdowneventos"
                      to="/eventos-publicos"
                    >
                      Eventos Públicos
                    </Link>
                  </div>
                </div> */}
                <div className="dropdown">
                  <h2>
                    Cadastro
                    <span>
                      <IoMdArrowDropdown />
                    </span>
                  </h2>
                  <div className="dropdowncontent">
                    {/* <Link className="linkDropdown" to="/cadastro-anunciante">
                      Anunciante
                    </Link> */}
                    <Link className="linkDropdown" to="/sign-up">
                      Consumidor
                    </Link>
                  </div>
                </div>
                <Link to="/quem-somos">Quem somos</Link>
                <a href="mailto:sac@lup.com.br">Contato</a>
                <Link  to="/criar-meu-evento">Criar meu evento</Link>
                {/* <div
                  className="my-event"
                  onClick={() => push("/criar-meu-evento")}
                >
                  <p className="my-event__label">Criar meu evento</p>
                </div> */}
              </nav>
              {/* <div className="dropdown login">
                <h2>
                  Login
                  <span>
                    <IoMdArrowDropdown />
                  </span>
                </h2>
                <div className="dropdowncontent">
                  <Link
                    onClick={() =>
                      (window.location.href =
                        "https://app.lup.com.br/anunciante/login.html")
                    }
                    className="linkDropdown"
                  >
                    Anunciante
                  </Link>
                  <Link
                    onClick={() =>
                      (window.location.href =
                        "https://app.lup.com.br/consumidor/login.html")
                    }
                    className="linkDropdown"
                  >
                    Consumidor
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* Header Mobile */}

        <div className="header-mobile">
          <div className="logo">
            <Link to="/">
              {" "}
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="header-esquerda">
            <nav className="menu">
              <span className="btnMenu btnMenuOpen" onClick={openMenu}>
                menu
              </span>

              <div className="menu-container menu-container-responsive">
                <div onClick={closeMenu} className="overlay"></div>
                <div className="menu-header">
                  <div className="logo">
                    <Link to="/">
                      {" "}
                      <img src={Logo} alt="logo" />
                    </Link>
                  </div>
                  <div className="navbar-close">
                    <span className="btnMenu btnMenuClose" onClick={closeMenu}>
                      menu
                    </span>
                  </div>
                </div>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>

                  {/* <li>
                    <a href="https://drops.lup.com.br/">Blog</a>
                  </li> */}

                  {/* <li onClick={dropdownOpen}>
                            <span>Consultar<span><IoMdArrowDropdown/></span></span>

                            <div className="dropdown-mobile">
                                <div><Link to="/convite">Convite</Link></div>
                                <div><Link to="/filter/params">Anunciantes</Link></div>
                                <div><Link to="/eventos-publicos">Eventos Públicos</Link></div>
                            </div>
                        </li> */}
                  <li onClick={dropdownOpenLast} style={{
                    cursor: "pointer"
                  }}>
                    <span>
                      Cadastro
                      <span>
                        <IoMdArrowDropdown />
                      </span>
                    </span>

                    <div className="dropdown-mobile-last">
                      {/* <div>
                        <Link to="/cadastro-anunciante">Anunciante</Link>
                      </div> */}
                      <div>
                        <Link to="/sign-up">Consumidor</Link>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="/">Quem somos</a>
                  </li>
                  <li>
                    <a href="mailto:sac@lup.com.br">Contato</a>
                  </li>
                  <li>
                    <Link to="/criar-meu-evento">Criar meu evento</Link>
                  </li>
                </ul>
                <div className="footer-menu">
                  <div>
                    <FiMail color="#a4416f" size="14" />
                    <span>contato@contato.com.br</span>
                  </div>
                </div>
              </div>
            </nav>
            <div className="menu-contato-responsive">
              <div className="redes-sociais">
                <a href="https://www.facebook.com/plataformalup/">
                  <img src={facebook} alt="redes sociais" />
                </a>
                <a href="https://www.instagram.com/lup.com.br/">
                  <img src={instagram} alt="redes sociais" />
                </a>
              </div>
              <div>
                <FiMail color="#a4416f" size="22" />
                <span>contato@contato.com.br</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
