import React, { useState } from "react";

const SucessPayment = () => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleReturnToApp = () => {
    try {
      window.location.href = "lup://home";

      // Se o deeplink falhar (usuário não tem o app instalado)
      setTimeout(() => {
        setErrorMessage(
          "Parece que o aplicativo não está instalado. Por favor, instale o aplicativo para continuar ou acesse pelo navegador."
        );
      }, 2000);

    } catch (error) {
      console.error("Erro ao tentar abrir o deeplink:", error);
      setErrorMessage(
        "Erro ao abrir o aplicativo. Verifique se ele está instalado ou acesse pelo navegador."
      );
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Pagamento Confirmado!</h1>
      <p style={styles.message}>
        Obrigado por realizar o pagamento. O processamento pode levar alguns minutos.
        Assim que for confirmado, você será notificado e poderá usufruir de todas as funcionalidades PRO.
      </p>
      {errorMessage && <div style={styles.error}>{errorMessage}</div>}
      <button style={styles.button} onClick={handleReturnToApp}>
        Voltar ao App
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "60vh",
    backgroundColor: "#f4f4f4",
    padding: "20px",
    textAlign: "center",
  },
  title: {
    fontSize: "24px",
    color: "#4CAF50",
    marginBottom: "16px",
  },
  message: {
    fontSize: "18px",
    color: "#333",
    marginBottom: "24px",
  },
  error: {
    marginTop: "16px",
    fontSize: "16px",
    color: "#f44336",
    backgroundColor: "#ffe9e9",
    padding: "10px",
    borderRadius: "5px",
    textAlign: "center",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#4CAF50",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default SucessPayment;
