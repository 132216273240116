import Footer from "../../components/Footer/Footer";
import React from "react";

export default function PoliticaDePrivacidadeApp() {
  return (
    <>
      <div style={{ padding: "80px" }}>
        <h1>Política de Privacidade do Lup Profissionais</h1>

        <h2>1. Introdução</h2>
        <p>
          Bem-vindo ao <strong>Lup Profissionais</strong>! Esta Política de
          Privacidade descreve como coletamos, usamos, armazenamos e protegemos
          as informações dos usuários em conformidade com a Lei Geral de
          Proteção de Dados (LGPD) no Brasil. Nosso compromisso é garantir que
          seus dados pessoais sejam tratados de forma segura e transparente.
        </p>

        <h2>2. Dados Coletados</h2>
        <p>
          Coletamos os seguintes dados pessoais para possibilitar o cadastro e
          uso dos serviços oferecidos pela plataforma:
        </p>
        <ul>
          <li>Nome completo</li>
          <li>Telefone</li>
          <li>Celular</li>
          <li>E-mail</li>
        </ul>
        <p>
          <strong>Nota:</strong> Não coletamos dados de navegação nem dados
          sensíveis.
        </p>

        <h2>3. Finalidade da Coleta de Dados</h2>
        <p>
          Os dados pessoais coletados são utilizados exclusivamente para as
          seguintes finalidades:
        </p>
        <ul>
          <li>Cadastro do usuário como prestador de serviços;</li>
          <li>
            Visualização dos serviços anunciados por outros usuários na
            plataforma.
          </li>
        </ul>
        <p>
          Os dados não são utilizados para personalização de anúncios ou
          campanhas de marketing.
        </p>

        <h2>4. Compartilhamento de Dados</h2>
        <p>
          <strong>Não compartilhamos dados pessoais com terceiros.</strong>{" "}
          Todas as informações fornecidas pelos usuários são utilizadas
          internamente, exclusivamente para as finalidades descritas acima.
        </p>

        <h2>5. Armazenamento e Segurança de Dados</h2>
        <p>
          Os dados fornecidos pelos usuários são armazenados indefinidamente em
          um banco de dados seguro, protegido por camadas de segurança e
          criptografia adequadas para garantir a integridade e a
          confidencialidade das informações.
        </p>

        <h2>6. Moderação e Monitoramento</h2>
        <p>
          Os anúncios cadastrados pelos usuários são submetidos a uma auditoria
          manual antes de sua publicação. O <strong>Lup Profissionais</strong>{" "}
          se reserva o direito de excluir anúncios que violarem as políticas de
          uso ou que incluam conteúdo proibido, como pornografia ou serviços não
          permitidos. Usuários que descumprirem as regras poderão ter suas
          contas suspensas ou excluídas.
        </p>

        <h2>7. Direitos dos Usuários</h2>
        <p>Os usuários têm o direito de:</p>
        <ul>
          <li>
            Acessar, corrigir ou excluir seus dados pessoais a qualquer momento.
            Para isso, é necessário entrar em contato com a equipe de suporte
            pelo e-mail{" "}
            <a href="mailto:lup.app.fornecedores@gmail.com">
              lup.app.fornecedores@gmail.com
            </a>
            .
          </li>
          <li>
            Desativar suas contas e solicitar a exclusão completa dos dados
            armazenados.
          </li>
        </ul>
        <p>
          <strong>Portabilidade de Dados:</strong> Atualmente, o{" "}
          <strong>Lup Profissionais</strong> não oferece suporte para
          portabilidade de dados.
        </p>

        <h2>8. Consentimento</h2>
        <p>
          Ao utilizar o <strong>Lup Profissionais</strong>, o usuário concorda
          com esta Política de Privacidade e com o tratamento de seus dados
          conforme descrito.
        </p>

        <h2>9. Alterações na Política de Privacidade</h2>
        <p>
          Qualquer alteração nesta Política de Privacidade será informada aos
          usuários por e-mail e via notificações dentro do aplicativo.
          Recomendamos que os usuários revisem periodicamente esta política para
          se manterem informados.
        </p>

        <h2>10. Contato</h2>
        <p>
          Em caso de dúvidas ou solicitações relacionadas à privacidade e
          proteção de dados, os usuários podem entrar em contato através do
          e-mail{" "}
          <a href="mailto:lup.app.fornecedores@gmail.com">
            lup.app.fornecedores@gmail.com
          </a>
          .
        </p>
      </div>
      <Footer />
    </>
  );
}

