import React, { useState, useEffect } from "react";
import "./listagem.css";
import Footer from "../../../components/Footer/Footer";
import { useParams, useHistory } from "react-router-dom";
import api from "../../../Services/api";
import image from "../../../Services/img";
import { Link } from "react-router-dom";

export default function Filtro() {
  let params = useParams();
  let history = useHistory();

  const [listar, setListar] = useState([]);
  const [estados, setEstados] = useState([]);
  const [estadoEscolhido, setEstadoEscolhido] = useState("");
  const [cidades, setCidades] = useState([]);
  const [escolhaCidade, setEscolhaCidade] = useState("");
  const [tipos, setTipos] = useState([]);
  const [ramo, setRamo] = useState([]);
  const [escolhaTipos, setEscolhaTipos] = useState("");
  const [temas, setTemas] = useState([]);
  const [escolhaTema, setEscolhaTema] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    fixLoad();
    let endereco = params.dados;

    endereco = endereco.split("&");

    let filtroEstado = endereco[0];
    let filtroCidade = endereco[1];
    let filtroTipo = endereco[2];
    let filtroTema = endereco[3];

    // console.log(filtroEstado)
    let estado = filtroEstado === "" ? "" : [{ id: filtroEstado }];
    let cidade = filtroCidade === "" ? "" : [{ id: filtroCidade }];
    let tipo = filtroTipo === "" ? "" : [{ id: filtroTipo }];
    let tema = filtroTema === "" ? "" : [{ id: filtroTema }];

    let dados = {
      estado_id: estado,
      cidade_id: cidade,
      idservicos: tipo,
      idtemaestabelecimento: tema,
    };
    setData(dados);

    loadListar(dados);
    // console.log(dados)
  }, []);

  const fixLoad = () => {
    document.querySelector("html").style = "scroll-behavior: unset;";
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  };

  async function loadListar(dadoUrl) {
    const response = await api.post("/buscar/anunciante", dadoUrl);
    // console.log("TESTE",response)
    for (var i = 0; response.data.data.length > i; i++) {
      var descricao = response.data.data[i].descricao;

      if (descricao != null) {
        var str = descricao.length;

        if (str > 100) {
          descricao = `${descricao.substring(0, 150)} ...`;
        }
      }

      response.data.data[i].descricao = descricao;
      response.data.data[
        i
      ].caminho = `${image}${response.data.data[i].caminho}`;
    }
    setListar(response.data.data);
  }

  useEffect(() => {
    async function loadEstados() {
      const response = await api.get("/estados");

      setEstados(response.data);
    }

    loadEstados();

    async function loadTipos() {
      const response = await api.get("/tiposeventos");

      setTipos(response.data);
    }
    loadTipos();

    async function loadTemas() {
      const response = await api.get("/temaestabelecimento");

      setTemas(response.data);
    }
    loadTemas();

    async function loadRamo() {
      const response = await api.get("/ramo");
      // console.log(response.data)
      setRamo(response.data);
    }

    loadRamo();
  }, []);

  function handleSelect(e) {
    let id;
    setEscolhaCidade("");
    setEstadoEscolhido(e.target.value);

    id = e.target.value;

    async function loadCidades() {
      const response = await api.get(`/cidades/${id}`);

      setCidades(response.data);
    }

    loadCidades();
  }
  function filtrar() {
    // let dados = `${estadoEscolhido}&${escolhaCidade}&${escolhaTipos}&${escolhaTema}`

    let filtroEstado = estadoEscolhido;
    let filtroCidade = escolhaCidade;
    let filtroTipo = escolhaTipos;
    let filtroTema = escolhaTema;

    let estado = filtroEstado === "" ? "" : [{ id: filtroEstado }];
    let cidade = filtroCidade === "" ? "" : [{ id: filtroCidade }];
    let tipo = filtroTipo === "" ? "" : [{ id: filtroTipo }];
    let tema = filtroTema === "" ? "" : [{ id: filtroTema }];

    let dados = {
      estado_id: estado,
      cidade_id: cidade,
      idservicos: tipo,
      // "tipoRamo": 12,
      idtemaestabelecimento: tema,
    };
    // console.log(dados)
    history.push(
      `/filter/${filtroEstado}&${filtroCidade}&${filtroTipo}&${filtroTema}`
    );
    setData(dados);
    loadListar(dados);
  }

  return (
    <>
      <div id="load-fornecedores" className="fornecedores-container">
        <div className="sidebar">
          <h1>Pesquisar</h1>
          <div className="form-sidebar">
            <select onChange={handleSelect}>
              <option value="">Selecione o estado</option>
              {estados.map((estado) => (
                <option value={estado.id} key={estado.id}>
                  {estado.nome}
                </option>
              ))}
            </select>
            <select onChange={(e) => setEscolhaCidade(e.target.value)}>
              <option value="">Selecione a cidade</option>
              {cidades.map((cidade) => (
                <option value={cidade.id} key={cidade.id}>
                  {cidade.nome}
                </option>
              ))}
            </select>
            <select onChange={(e) => setEscolhaTipos(e.target.value)}>
              <option value="">Tipo do evento</option>
              {tipos.map((tipo) => (
                <option value={tipo.id} key={tipo.id}>
                  {tipo.descricao}
                </option>
              ))}
            </select>

            <select onChange={(e) => setEscolhaTipos(e.target.value)}>
              <option value="">Tipo do serviço</option>
              {ramo.map((ramo) => (
                <option value={ramo.id} key={ramo.id}>
                  {ramo.descricao}
                </option>
              ))}
            </select>

            <select onChange={(e) => setEscolhaTema(e.target.value)}>
              <option value="">Selecione o tema</option>
              {temas.map((tema) => (
                <option value={tema.id} key={tema.id}>
                  {tema.descricao}
                </option>
              ))}
            </select>

            <button onClick={filtrar}>Pesquisar</button>
          </div>
        </div>
        <div className="main-fornecedores">
          <h1>Resultado da Pesquisa:</h1>
          <div>
            {listar[0] === undefined ? (
              <h1 className="messageError">Nenhum resultado encontrado</h1>
            ) : (
              listar.map((item) => (
                <div className="card-fornecedores" key={item.id_servico}>
                  <div className="bg-card-fornecedores">
                    <div className="img-card-fornecedores">
                      <Link to={`/anuncio/${item.id_servico}/${item.id}`}>
                        {" "}
                        <img src={item.caminho}></img>{" "}
                      </Link>
                    </div>
                  </div>
                  <div className="body-card-fornecedores">
                    <Link to={`/anuncio/${item.id_servico}/${item.id}`}>
                      {" "}
                      <h1>{item.fantasia}</h1>{" "}
                    </Link>
                    <p>{item.descricao}</p>

                    <div className="link-card-fornecedores">
                      <Link to={`/anuncio/${item.id_servico}/${item.id}`}>
                        <span>Saiba mais</span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
