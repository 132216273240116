import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import React from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import "../src/App.css";
import Header from "./components/header/Header";
import DashboardHeader from "./components/dashboardHeader/DashboardHeader";
import Routes from "./routes";
import { AuthProvider } from "./context/AuthContext";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
});

function AppContent() {
  const location = useLocation();
  const isDashboardRoute = location.pathname.startsWith("/dashboard");

  return (
    <>
      {isDashboardRoute ? <DashboardHeader /> : <Header />}
      <Routes />
    </>
  );
}

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <BrowserRouter>
          <AppContent />
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
}

