import React, { useEffect, useState } from "react";
import "./css/index.css";
import { useUser } from "../../context/AuthContext";
import newBaseApi from "../../Services/newBaseApi";
import { Link } from "react-router-dom";

export default function Dashboard() {
  const [orcamentosPendentes, setOrcamentosPendentes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { user } = useUser();

  useEffect(() => {
    if (user) {
      const fetchOrcamentosPendentes = async () => {
        try {
          setLoading(true);
          const response = await newBaseApi.get(
            `/consumidor/eventos/listOrcamentos-pendentes/${user.id}`
          );
          setOrcamentosPendentes(response.data.data); // Acessa o array `data` retornado pelo backend
        } catch (err) {
          console.error("Erro ao buscar orçamentos pendentes:", err);
          setError("Erro ao buscar orçamentos pendentes.");
        } finally {
          setLoading(false);
        }
      };

      fetchOrcamentosPendentes();
    }
  }, [user]);

  return (
    <div className="container">
      <div className="dashboard-container">
        <header className="dashboard-header">
          <p>Bem vindo {user.name}!</p>
        </header>

        {loading ? (
          <p>Carregando orçamentos...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <section className="orcamentos-section">
            <p>Orçamentos Pendentes</p>
            <div className="grid-list">
              {orcamentosPendentes.length > 0 ? (
                orcamentosPendentes.map((orcamento) => (
                  <div key={orcamento.id} className="orcamento-card-dashboard">
                    <div className="orcamento-info">
                      <p>
                        <strong>Evento:</strong> {orcamento.evento_nome}
                      </p>
                      <p>
                        <strong>Anunciante:</strong> {orcamento.customer.name}
                      </p>
                      <p>
                        <strong>Tipo:</strong> {orcamento.evento.tipo_name}
                      </p>
                      <p>
                        <strong>Valor:</strong> R$ {orcamento.total_amount/100}
                      </p>
                    </div>
                    <Link
                      to={`/dashboard/detalhes-evento/${orcamento.evento_id}`}
                      state={{ id: orcamento.evento_id, evento_nome: orcamento.evento_nome }}
                    >
                      <button>Visualizar Evento</button>
                    </Link>
                  </div>
                ))
              ) : (
                <p>Nenhum orçamento pendente no momento.</p>
              )}
            </div>
          </section>
        )}
      </div>
    </div>
  );
}