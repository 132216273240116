import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import newBaseApi from "../../../Services/newBaseApi"; 
import "./css/index.css";

export default function DetalhesEvento() {
  const { id } = useParams();
  const [evento, setEvento] = useState(null);
  const [orcamentos, setOrcamentos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await newBaseApi.get(`/consumidor/eventos/listOrcamentos/${id}`);
        const data = response.data;

        setEvento({
          evento_id: data.evento_id,
          nome: data.nome,
          data: data.data,
          inicio: data.inicio,
          fim: data.fim,
          endereco: data.endereco,
          numero: data.numero,
          bairro: data.bairro,
          numero_convidados: data.numero_convidados,
          descricao: data.descricao,
          unicodia: data.unicodia,
          datadoeventofim: data.datadoeventofim,
          cidade: data.cidade,
          estado: data.estado
        });

        setOrcamentos(data.orcamentos);
      } catch (error) {
        toast.error("Erro ao carregar os dados do evento");
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  const toggleDetalhes = (index) => {
    const updatedOrcamentos = [...orcamentos];
    updatedOrcamentos[index].detalhesVisiveis = !updatedOrcamentos[index].detalhesVisiveis;
    setOrcamentos(updatedOrcamentos);
  };

  const alterarStatus = async (orcamentoId, novoStatus) => {
    try {
      await newBaseApi.put("/consumidor/eventos/alterarStatusOrcamento", {
        orcamento_id: orcamentoId,
        status: novoStatus,
      });

      const updatedOrcamentos = orcamentos.map((orcamento) =>
        orcamento.orcamento_id === orcamentoId
          ? { ...orcamento, status: novoStatus }
          : orcamento
      );
      setOrcamentos(updatedOrcamentos);

      toast.success(`Status alterado para ${novoStatus}`);
    } catch (error) {
      toast.error("Erro ao alterar o status do orçamento");
      console.error(error);
    }
  };

  const getStatusClass = (status) => {
    if (status === "aprovado") return "status-aprovado";
    if (status === "reprovado") return "status-reprovado";
    return "status-pendente";
  };

  return (
    <div className="container">
      <Toaster />
      <div className="detalhes-container">
        <Link to="/dashboard">
          <button className="back-button">⬅ Voltar para o Dashboard</button>
        </Link>

        {evento && (
          <section className="evento-detalhes">
            <h1 className="evento-titulo">{evento.nome}</h1>
            <div className="evento-datas">
              {evento.unicodia === "sim" ? (
                <>
                  <div className="data-horario">
                    <span className="data">📅 {evento.data}</span>
                    <span className="horario">🕒 {evento.inicio} - {evento.fim}</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="data-horario">
                    <span className="data">📅 Início: {evento.data}</span>
                    <span className="horario">🕒 {evento.inicio}</span>
                  </div>
                  <div className="data-horario">
                    <span className="data">📅 Término: {evento.datadoeventofim}</span>
                    <span className="horario">🕒 {evento.fim}</span>
                  </div>
                </>
              )}
            </div>
            <p>
              <strong>Localização:</strong>{" "}
              {evento.cidade && evento.estado ? (
                <>
                  {evento.endereco && <>{evento.endereco}{evento.numero && `, ${evento.numero} - `}</>}
                  {evento.bairro && <>{evento.bairro}, </>}
                  {`${evento.cidade} - ${evento.estado}`}
                </>
              ) : (
                "Localização não informada"
              )}
            </p>


            <p>
              <strong>Número de Convidados:</strong> {evento.numero_convidados || "Não informado"}
            </p>
            <p>
              <strong>Descrição:</strong> {evento.descricao || "Não informado"}
            </p>
          </section>
        )}

        <section className="orcamentos-detalhes">
          <h2>Orçamentos</h2>
          {orcamentos.map((orcamento, index) => (
            <div key={orcamento.orcamento_id} className="orcamento-card">
              <div className="orcamento-header-buttons">
                <div className="orcamento-header">
                  <p>
                    <strong>Fornecedor:</strong> {orcamento.anunciante.nome}
                  </p>
                  <p>
                    <strong>Valor Total:</strong> R$ {orcamento.valor_total / 100}
                  </p>
                  <p>
                    <strong>Status:</strong>{" "}
                    <span className={getStatusClass(orcamento.status)}>
                      {orcamento.status}
                    </span>
                  </p>
                  <button
                    onClick={() => toggleDetalhes(index)}
                    className="detalhes-button"
                  >
                    {orcamento.detalhesVisiveis ? "▲ Ver Menos" : "▼ Ver Mais"}
                  </button>
                </div>
                {orcamento.status === "pendente" && (
                  <div className="action-buttons">
                    <button
                      className="accept-button"
                      onClick={() => alterarStatus(orcamento.orcamento_id, "aprovado")}
                    >
                      Aceitar
                    </button>
                    <button
                      className="reject-button"
                      onClick={() => alterarStatus(orcamento.orcamento_id, "reprovado")}
                    >
                      Recusar
                    </button>
                  </div>
                )}
              </div>

              {orcamento.detalhesVisiveis && (
                <div className="orcamento-detalhes">
                  <p>
                    <strong>E-mail:</strong> {orcamento.anunciante.email}
                  </p>
                  <p>
                    <strong>Telefone:</strong> {orcamento.anunciante.telefone || "Não informado"}
                  </p>

                  <div className="itens-orcamento">
                    <h3>Itens do Orçamento</h3>
                    {orcamento.itens.length > 0 ? (
                      <table className="itens-tabela">
                        <thead>
                          <tr>
                            <th>Nome do Item</th>
                            <th>Quantidade</th>
                            <th>Valor Total (R$)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orcamento.itens.map((item, i) => (
                            <tr key={i}>
                              <td>{item.description}</td>
                              <td>{item.quantity}</td>
                              <td>{(item.total_amount / 100).toFixed(2)}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan="2" className="total-label">
                              Total dos Itens
                            </td>
                            <td className="total-valor">
                              R$ {orcamento.itens.reduce((sum, item) => sum + item.total_amount, 0) / 100}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    ) : (
                      <p className="no-items-message">Este orçamento não possui itens.</p>
                    )}
                  </div>

                  <p>
                    <strong>Tipo de Pagamento:</strong>{" "}
                    {orcamento.tipo_pagamento ? JSON.parse(orcamento.tipo_pagamento).join(", ") : "Não informado"}
                  </p>

                  <p>
                    <strong>Descrição do Pagamento:</strong>{" "}
                    {orcamento.descricao_pagamento || "Não informado"}
                  </p>
                </div>
              )}

            </div>
          ))}
        </section>
      </div>
    </div>
  );
}
