import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

export default function Profile() {
  const { user, logout } = useContext(AuthContext);

  return (
    <div>
      <h1>Bem-vindo, {user?.name || "Usuário"}</h1>
      <button onClick={logout}>Sair</button>
    </div>
  );
}
