import Footer from "../../../components/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import api from "../../../Services/api";
import "./styles.css";
import { toast } from "react-hot-toast";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export function SignUp() {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();
  const [error, setError] = useState(undefined);

  const onSubmit = async (data) => {
    try {
      setError(undefined);
      await api.post("/consumidor/register", data);
      setOpenSuccess(true);
      history.push("/login-consumidor");
      reset();
    } catch (error) {
      setError(error.response?.data.message || error.message);
      console.error("Erro no cadastro:", error.response?.data || error.message);
      toast.error("Erro ao realizar o cadastro. Tente novamente!");
    }
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <div className="container-fluid mt-60">
        <div className="row mt-20">
          <div className="col-md-12 text-center">
            <h1 className="mt-30 cadastro__title">Cadastro Consumidor</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 offset-md-4 mt-30">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group text-center login-block">
                <label htmlFor="usuario">Nome Completo</label>

                <div className="user">
                  <FontAwesomeIcon className="user-icon" icon={faUser} />
                  <input
                    type="text"
                    className="form-control"
                    {...register("name", {
                      required: "Nome completo é obrigatório",
                    })}
                  />
                </div>
                {errors.name && (
                  <p className="error-message">{errors.name.message}</p>
                )}
              </div>

              <div className="form-group text-center login-block">
                <label htmlFor="usuario">Telefone</label>
                <div className="user">
                  <FontAwesomeIcon className="user-icon" icon={faPhone} />
                  <input
                    type="text"
                    className="form-control"
                    {...register("cellphone", {
                      required: "Telefone é obrigatório",
                    })}
                  />
                </div>
                {errors.cellphone && (
                  <p className="error-message">{errors.cellphone.message}</p>
                )}
              </div>

              <div className="form-group text-center login-block">
                <label htmlFor="usuario">Email</label>

                <div className="user">
                  <FontAwesomeIcon className="user-icon" icon={faUser} />
                  <input
                    type="text"
                    className="form-control"
                    {...register("email", {
                      required: "Email é obrigatório",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Email inválido",
                      },
                    })}
                  />
                </div>
                {errors.email && (
                  <p className="error-message">{errors.email.message}</p>
                )}
              </div>

              <div className="form-group text-center login-block">
                <label htmlFor="senha">Senha</label>

                <div className="user">
                  <FontAwesomeIcon className="user-icon" icon={faLock} />
                  <input
                    type="password"
                    className="form-control"
                    {...register("password", {
                      required: "Senha é obrigatória",
                      minLength: {
                        value: 6,
                        message: "Senha deve ter no mínimo 6 caracteres",
                      },
                    })}
                  />
                </div>
                {errors.password && (
                  <p className="error-message">{errors.password.message}</p>
                )}
              </div>

              {error && (
                <p className="error-message" style={{
                  textAlign: "center"
                }}>{error}</p>
              )}

              <div class="form-group text-center">
                <button type="submit" className="btn btn-login" disabled={isSubmitting}>
                  {isSubmitting ? "Cadastrando" : "Cadastrar"}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="row mt-120"></div>
      </div>
      <Footer />

      <Snackbar
        open={openSuccess}
        autoHideDuration={2000}
        onClose={handleCloseSuccess}
      >
        <Alert onClose={handleCloseSuccess} severity="success">
          Cadastro realizado com sucesso!
        </Alert>
      </Snackbar>

      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          E-mail ou senha inválidos!
        </Alert>
      </Snackbar>
    </>
  );
}

